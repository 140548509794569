import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalaryCalculatorModule } from '@metallgehalt/salary-calculator';

import { SalaryRoutingModule } from './salary-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SalaryCalculatorModule, SalaryRoutingModule],
})
export class SalaryModule {}
