import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'metall-rechner-google-adsense',
  templateUrl: './google-adsense.component.html',
  styleUrls: ['./google-adsense.component.scss']
})
export class GoogleAdsenseComponent {
  @Input() dataAdClient!: string;
  @Input() dataAdSlot!: string;
  @Input() dataAdFormat!: string;
  @Input() dataFullWidthResponsive: boolean = true;

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    let script = this.renderer2.createElement('script');
    script.crossorigin = 'anonymous';
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2750538732947634';

    this.renderer2.appendChild(this.document.body, script);
  }
}
