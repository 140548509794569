<div class="d-flex align-items-center justify-content-between">
  <div class="me-2">
    <span>
      Hilf uns das Nutzerverhalten besser zu verstehen indem du Cookies zulässt. Hier findest du unsere
      <a [routerLink]="['/datenschutz']">Datenschutzerklärung</a>.
    </span>
  </div>
  <div class="d-flex flex-column">
    <button mat-stroked-button color="accent" (click)="accept()">Annehmen</button>
    <button mat-stroked-button color="primary" class="mt-1" (click)="decline()">Ablehnen</button>
  </div>
</div>
