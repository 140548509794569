import { Route } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { AboutLaborAgreementComponent } from './components/about-labor-agreement/about-labor-agreement.component';

export const salaryCalculatorRoutes: Route[] = [
  { path: '', redirectTo: 'gehalt', pathMatch: 'full' },
  {
    path: 'gehalt',
    component: ContainerComponent,
    children: [
      { path: '', redirectTo: 'rechner', pathMatch: 'full' },
      { path: 'rechner', component: CalculatorComponent },
    ],
  },
  {
    path: 'info',
    component: ContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'über-ig-metall-tarifvertrag',
        pathMatch: 'full',
      },
      {
        path: 'über-ig-metall-tarifvertrag',
        component: AboutLaborAgreementComponent,
      },
    ],
  },

  // redirect legacy routes
  {
    path: 'salary',
    children: [
      { path: 'calculator', redirectTo: '/gehalt/rechner', pathMatch: 'full' },
    ],
  },
];
