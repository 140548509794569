import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

import * as echarts from 'echarts';

const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

@Component({
  selector: 'metall-rechner-monthly-chart',
  templateUrl: './monthly-chart.component.html',
  styleUrls: ['./monthly-chart.component.scss'],
  providers: [CurrencyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthlyChartComponent {
  @ViewChild('echarts') echarts!: ElementRef;

  @Input() set data(data: any) {
    if (data) {
      const monthlyBaseSalary: Array<any> = [];
      const extraHoursSalary: Array<any> = [];
      const extraPayments: Array<any> = [];
      let extraHoursSalaryName = 'Überstundenzuschlag';

      if (data.extraHoursSalary < 0) {
        extraHoursSalaryName = 'Minderstundenabzug';
      }

      MONTHS.forEach(month => {
        monthlyBaseSalary.push({ month, value: data.monthlyBaseSalary, name: 'Monatliches Grundgehalt' });
        extraHoursSalary.push({ month, value: data.extraHoursSalary, name: extraHoursSalaryName });

        const extraPayment = data.extraPayments
          .filter((extraPayment: any) => extraPayment.paymentMonth === month)
          .reduce(
            (acc: any, extraPayment: any) => {
              return { money: (acc.money += extraPayment.money), name: (acc.name += `${extraPayment.name}, `) };
            },
            { money: 0, name: '' }
          );
        if (extraPayment) {
          extraPayments.push({ month, value: extraPayment.money, name: extraPayment.name.substring(0, extraPayment.name.length - 2) });
        } else {
          extraPayments.push({ month, value: 0 });
        }
      });

      if (this.chartInstance) {
        this.chartInstance.setOption({
          dataset: [
            { dimensions: ['month', 'value', 'name'], source: monthlyBaseSalary },
            { dimensions: ['month', 'value', 'name'], source: extraHoursSalary },
            { dimensions: ['month', 'value', 'name'], source: extraPayments }
          ]
        });
      } else {
        this.option.dataset = [
          { dimensions: ['month', 'value', 'name'], source: monthlyBaseSalary },
          { dimensions: ['month', 'value', 'name'], source: extraHoursSalary },
          { dimensions: ['month', 'value', 'name'], source: extraPayments }
        ];
      }
    }
  }

  chartInstance!: echarts.ECharts;
  option: echarts.EChartsOption = {
    legend: {},
    tooltip: {
      confine: true,
      formatter: (params: any) => {
        let message = '';
        message += `${params.data.month}`;
        message += `<br/>${params.marker}<b>${params.data.name}</b>: ${this.currencyPipe.transform(params.data.value, 'EUR')}`;
        return message;
      }
    },
    color: ['#A7D2CB', '#A6DEB5', '#AEE8CE', '#AEE2E8', '#A6C9DE', '#7E9E99'],
    xAxis: [
      {
        type: 'category',
        axisLabel: {
          show: true,
          interval: 0,
          rotate: 45
        },
        data: MONTHS
      }
    ],
    yAxis: {},
    grid: {
      containLabel: true,
      bottom: 0
    },
    series: [
      {
        type: 'bar',
        name: 'Monatliches Grundgehalt',
        stack: 'money',
        encode: { x: 'month', y: 'value' },
        color: '#7E9E99',
        datasetIndex: 0
      },
      {
        type: 'bar',
        name: 'Überstundenzuschlag',
        stack: 'money',
        encode: { x: 'month', y: 'value' },
        color: '#A7D2CB',
        datasetIndex: 1
      },
      {
        type: 'bar',
        name: 'Zusatzgeld',
        stack: 'money',
        encode: { x: 'month', y: 'value' },
        color: '#AEE8CE',
        datasetIndex: 2
      }
    ]
  };
  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.chartInstance = echarts.init(this.echarts.nativeElement);
    this.chartInstance.setOption(this.option);

    const resizeObserver = new ResizeObserver(entries => {
      this.chartInstance.resize({
        animation: {
          duration: 500,
          easing: 'cubicIn'
        }
      });
    });

    resizeObserver.observe(this.echarts.nativeElement);
  }
}
