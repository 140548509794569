<div class="row">
  <div class="col-12 col-md-6">
    <div *ngIf="chartData && !loading" class="h-100">
      <metall-rechner-monthly-chart [data]="chartData"></metall-rechner-monthly-chart>
    </div>

    <div *ngIf="!chartData">
      <h2 class="welcome">Der Gehaltsrechner für den IG Metall Tarifvertrag in der Metall- und Elektroindustrie</h2>
      <div class="info-paragraph mb-3">
        <div class="icon me-3"><i class="fa-solid fa-info fa-3x"></i></div>
        <p>
          Wenn du endlich verstehen möchtest, wie sich dein monatliches und jährliches Entgelt im IG Metall Tarifvertrag für die <b>Metall- und Elektroindustrie</b> zusammen setzt,
          bist du hier genau richtig. Im Gehaltsrechner wird aufgeschlüsselt, wie hoch deine monatlichen Gehaltszahlungen sind und wann Zusatzentgelte (Tarifliche Zusatzentgelte)
          gezahlt werden. Du hast Anregungen, wie man den Rechner verbessern kann? Schreibe mir an <a href="mailto:hi@metallgehalt.de">hi&#64;metallgehalt.de</a>!
        </p>
      </div>
      <div class="info-paragraph mb-3">
        <div class="icon me-3"><i class="fa-solid fa-bug fa-3x"></i></div>
        <p>
          Falls dir ein Fehler auffällt, zögere nicht mir eine kurze E-Mail (<a href="mailto:bug@metallgehalt.de">bug&#64;metallgehalt.de</a>) zu schreiben, sodass ich das
          schnellstmöglich anpassen kann! Falls es um falsche Daten geht, schick mir gerne auch direkt die Quelle mit, wo du die korrekten Daten gefunden hast. Das macht es für
          mich einfacher, den Fehler nachzuvollziehen.
        </p>
      </div>
      <div class="info-paragraph mb-3">
        <div class="icon me-3">
          <i class="fa-solid fa-triangle-exclamation fa-3x"></i>
        </div>
        <p>
          <b>Disclaimer:</b> Trotz intensiver Recherche können in der Berechnung Fehler sein. Das Gehalt kann zum Teil individuell abweichen, wenn Arbeitgeber die Möglichkeit
          nutzen, Zusatzzahlungen gemäß Tarifvertrag zu verschieben. Nutze diesen Rechner als Orientierung.
        </p>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Tarifabschluss</mat-label>
        <mat-select formControlName="labourAgreementId" placeholder="Tarifvertrag" [compareWith]="compareWith">
          <mat-option *ngFor="let labourAgreement of labourAgreements" [value]="labourAgreement.id">
            {{ labourAgreement.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Region</mat-label>
        <mat-select formControlName="region" name="food" placeholder="Region">
          <mat-option *ngFor="let region of regions" [value]="region.key">
            {{ region.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Entgeltgruppe</mat-label>
        <mat-select formControlName="payGrade" name="food" placeholder="Entgeltgruppe">
          <mat-option *ngFor="let payGrade of payGrades" [value]="payGrade">
            {{ payGrade }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Gruppenstufe</mat-label>
        <mat-select formControlName="payGradeStep" name="food" placeholder="Gruppenstufe">
          <mat-option *ngFor="let payGradeStep of payGradeSteps" [value]="payGradeStep">
            {{ payGradeStep }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="row mb-3">
        <div class="col-12 col-xl-6 d-flex align-items-baseline">
          <mat-form-field appearance="outline" class="w-100 mb-2">
            <mat-label>wöchentliche Grundarbeitszeit</mat-label>
            <input formControlName="weeklyWorkingHours" type="number" matInput placeholder="wöchentliche Arbeitszeit" />

            <mat-icon matSuffix><i class="fa-solid fa-clock me-3"></i></mat-icon>

            <mat-hint *ngIf="form.get('weeklyWorkingHours')?.errors" class="error">Zahl zwischen 30 und 45</mat-hint>
            <mat-hint
              *ngIf="!form.get('weeklyWorkingHours')?.errors"
              class="info"
              matTooltip="Diese Zahl gibt die übliche Wochenarbeitszeit in deinem Unternehmen an. Sie wird benötigt, um Überstunden richtig zu berechnen und hat keinerlei Auswirkungen, solange keine Mehr/Minderstunden im Feld nebenan angegeben werden."
              #tooltipWeeklyWorkingHoursExplaination="matTooltip"
              (click)="tooltipWeeklyWorkingHoursExplaination.show()"
            >
              Warum ändert sich das Gehalt nicht?
            </mat-hint>
          </mat-form-field>
          <div class="ms-3">
            <i
              class="fa-solid fa-circle-info"
              matTooltip="Gib hier die wöchentliche Arbeitszeit an, die in deinem Unternehmen üblich ist. Manche Unternehmen (z.B. im Osten) haben eine andere Grundarbeitszeit (z.B. 37h). Dieser Wert wirkt sich nur auf das Gehalt aus, wenn du auch zusätzliche Wochenarbeitszeit (z.B. Überstunden, Erhöhung der Arbeitszeit, usw.) angibst."
              #tooltipWeeklyWorkingHours="matTooltip"
              (click)="tooltipWeeklyWorkingHours.show()"
            ></i>
          </div>
        </div>

        <div class="col-12 col-xl-6 d-flex align-items-baseline">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>wöchentliche zusätzliche Arbeitszeit</mat-label>
            <input formControlName="weeklyAdditionalWorkingHours" type="number" matInput placeholder="wöchentliche zusätzliche Arbeitszeit" />

            <mat-icon matSuffix><i class="fa-solid fa-clock me-3"></i></mat-icon>

            <mat-hint *ngIf="form.get('weeklyAdditionalWorkingHours')?.errors" class="error">Zahl zwischen -20 und 20</mat-hint>
          </mat-form-field>
          <div class="ms-3">
            <i
              class="fa-solid fa-circle-info"
              matTooltip="Gib hier zusätzliche Stunden oder reduzierte Stunden an. Dies können z.B. Überstunden, Erhöhung oder Veringerung der Arbeitszeit, oder ähnliches sein. Falls du deine Stunden reduziert hast, kannst du einen negativen Wert angeben."
              #tooltipAdditionalWorkingHours="matTooltip"
              (click)="tooltipAdditionalWorkingHours.show()"
            ></i>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-xl-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Weihnachtsgeld</mat-label>
            <input type="number" placeholder="Weihnachtsgeld" aria-label="Number" matInput formControlName="christmasMoney" [matAutocomplete]="auto" />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option [value]="25"> 25% </mat-option>
              <mat-option [value]="30"> 30% </mat-option>
              <mat-option [value]="35"> 35% </mat-option>
              <mat-option [value]="40"> 40% </mat-option>
              <mat-option [value]="45"> 45% </mat-option>
              <mat-option [value]="50"> 50% </mat-option>
              <mat-option [value]="55"> 55% </mat-option>
              <mat-option [value]="60"> 60% </mat-option>
            </mat-autocomplete>

            <mat-icon matSuffix><i class="fa-solid fa-percent me-3"></i></mat-icon>

            <mat-hint *ngIf="form.get('christmasMoney')?.errors" class="error"> Das Weihnachtsgeld muss zwischen 0% und 200% liegen. </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-12 col-xl-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Leistungszulage</mat-label>
            <input formControlName="efficiencyBonus" type="number" matInput placeholder="Leistungszulage" />

            <mat-icon matSuffix><i class="fa-solid fa-percent me-3"></i></mat-icon>

            <mat-hint *ngIf="form.get('efficiencyBonus')?.errors" class="error"> Die Leistungszulage muss zwischen 0% und 150% liegen. </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-12 col-xl-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Sonderzahlung</mat-label>
            <input formControlName="additionalExtraPayment" type="number" matInput placeholder="Sonderzahlung" />

            <mat-icon matSuffix><i class="fa-solid fa-euro-sign me-3"></i></mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="mb-3">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> erweiterte Einstellungen </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-12">
                <mat-checkbox formControlName="exchangeTZugA" class="w-100"> T-Zug A als freie Tage </mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="row">
        <div class="col-12">
          <mat-checkbox formControlName="rememberInputs" class="w-100"> Eingaben merken </mat-checkbox>
        </div>
      </div>
    </form>

    <div class="alert alert-info" role="alert" *ngIf="labourAgreementInfo">
      {{ labourAgreementInfo }}
    </div>

    <table class="table table-sm" *ngIf="chartData && !loading">
      <thead>
        <tr>
          <th></th>
          <th scope="col" class="text-end">Gehalt</th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngIf="efficiencyBonusSalary > 0">
          <th scope="row">Tarifliches Grundgehalt</th>
          <td class="text-end">{{ baseSalary | currency : 'EUR' }}</td>
        </tr>
        <tr *ngIf="efficiencyBonusSalary > 0">
          <th scope="row">Leistungszulage ({{ form.get('efficiencyBonus')?.value / 100 | percent : '1.2-2' }})</th>
          <td class="text-end">
            {{ efficiencyBonusSalary | currency : 'EUR' }}
          </td>
        </tr>
      </tbody>
      <tbody class="table-group-divider" *ngIf="extraHoursSalary > 0">
        <tr>
          <th scope="row">Monatliches Grundgehalt</th>
          <td class="text-end">{{ monthlyBaseSalary | currency : 'EUR' }}</td>
        </tr>
        <tr>
          <th scope="row">Mehrstunden</th>
          <td class="text-end">{{ extraHoursSalary | currency : 'EUR' }}</td>
        </tr>
      </tbody>
      <tbody class="table-group-divider" *ngIf="extraHoursSalary < 0">
        <tr>
          <th scope="row">Monatliches Grundgehalt</th>
          <td class="text-end">{{ monthlyBaseSalary | currency : 'EUR' }}</td>
        </tr>
        <tr>
          <th scope="row">Minderstundenabzug</th>
          <td class="text-end">{{ extraHoursSalary | currency : 'EUR' }}</td>
        </tr>
      </tbody>
      <tbody class="table-group-divider">
        <tr>
          <th scope="row">Monatliches Gehalt</th>
          <td class="text-end">{{ monthlySalary | currency : 'EUR' }}</td>
        </tr>
      </tbody>
      <tbody class="table-group-divider">
        <tr *ngFor="let extraPayment of extraPayments">
          <th scope="row">
            {{ extraPayment.name }} ({{ extraPayment.paymentMonth }})
            <i class="fa-solid fa-circle-info" [matTooltip]="extraPayment.info" #tooltip="matTooltip" (click)="tooltip.show()"></i>
          </th>
          <td class="text-end">{{ extraPayment.money | currency : 'EUR' }}</td>
        </tr>
        <tr *ngIf="additionalExtraPayment">
          <th scope="row">Sonderzahlung</th>
          <td class="text-end">{{ additionalExtraPayment | currency : 'EUR' }}</td>
        </tr>
      </tbody>
      <tfoot class="table-group-divider">
        <tr>
          <th scope="row">Brutto Jahresgehalt</th>
          <td class="text-end">{{ sum | currency : 'EUR' }}</td>
        </tr>
      </tfoot>
    </table>
    <div *ngIf="url">
      <span class="copy" [cdkCopyToClipboard]="url" (cdkCopyToClipboardCopied)="copied($event)">
        Link zur Auswahl in Zwischenablage kopieren
        <i class="fa-regular fa-clone"></i>
      </span>
    </div>
  </div>
</div>
