import { Route } from '@angular/router';

import { ImpressumComponent } from '@metallgehalt/shared';
import { PrivacyPolicyComponent } from '@metallgehalt/shared';

export const appRoutes: Route[] = [
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: PrivacyPolicyComponent },
  { path: '**', redirectTo: 'gehalt', pathMatch: 'full' },
];
