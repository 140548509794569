<div class="row mb-3">
  <div class="col">
    <p>
      Hier findest du alle Informationen rund um die Tarifrunden in der IG Metall Metall- und Elektroindustrie. Es werden die Ergebnisse detailiert aufgeschlüsselt und erläutert.
      Auch Änderungen, die nicht offensichtlich sind, werden hier beschrieben.
    </p>
  </div>
</div>
<div class="row mb-3">
  <div class="col">
    <div class="h-100 p-3 p-md-4 bg-body-tertiary border rounded-3">
      <h2>Tarifrunde 2024</h2>
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Dauerhafte erhöhung für <i>Auszubildende</i></div>
                <span class="text-success">+140€</span>
              </div>
              <span class="badge bg-primary rounded-pill">ab Jan. 2025</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Einmalzahlung</div>
                <span class="text-success">+600€</span>
              </div>
              <span class="badge bg-primary rounded-pill">bis Feb. 2025</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tabellenwirksame Erhöhung (nicht für Auszubildende)</div>
                <span class="text-success">+2,0%</span>
              </div>
              <span class="badge bg-primary rounded-pill">ab Apr. 2025</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Erhöhung des Tariflichen Zusatzgeldes B (T-ZUG B)</div>
                <div>Von <span class="text-danger">18,5%</span> auf <span class="text-success">26,5%</span></div>
              </div>
              <span class="badge bg-primary rounded-pill">ab 2026</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tabellenwirksame Erhöhung (auch für Auszubildende)</div>
                <span class="text-success">+3,1%</span>
              </div>
              <span class="badge bg-primary rounded-pill">ab Apr. 2026</span>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-6">
          <p>
            Wichtig: Es handelt sich aktuell noch um einen <strong>Pilotabschluss</strong>, der durch die IG Metall-Bezirke Küste und Bayern erzielt wurde. Dieser Pilotabschluss
            gilt nicht automatisch in allen anderen Bezirken. Alle anderen Bezirke müssen Übernahmeverhandlungen führen.
          </p>
          <p>
            Im November 2024 hat die IG Metall in Tarifverhandlungen Tabellenwirksame (dauerhafte) Erhöhungen von insgesamt 5,1% erreicht. Zusätzlich gibt es eine Einmalzahlung in
            Höhe von 600€. Der Tarifvertrag läuft bis zum 31. Oktober 2026.
          </p>
          <p>Es wurden weiterhin die Auszahlung von Tariflichen Zusatzgeldern getauscht. So wird das T-ZUG T zukünftig im Juli ausgezahlt und T-ZUG B im Februar.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="h-100 p-3 p-md-4 bg-body-tertiary border rounded-3">
      <h2>Tarifrunde 2022</h2>
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Inflationsausgleichsprämie</div>
                <span class="text-success">+1500€</span>
              </div>
              <span class="badge bg-primary rounded-pill">bis Feb. 2023</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tabellenwirksame Erhöhung</div>
                <span class="text-success">+5,2%</span>
              </div>
              <span class="badge bg-primary rounded-pill">ab Jun. 2023</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Inflationsausgleichsprämie</div>
                <span class="text-success">+1500€</span>
              </div>
              <span class="badge bg-primary rounded-pill">bis Feb. 2024</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tabellenwirksame Erhöhung</div>
                <span class="text-success">+3,3%</span>
              </div>
              <span class="badge bg-primary rounded-pill">ab Mai 2024</span>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-6">
          <p>
            Im November 2022 hat die IG Metall in Tarifverhandlungen Tabellenwirksame (dauerhafte) Erhöhungen von insgesamt 8,5% erreicht. Zusätzlich gibt es insgesamt 3000€
            Inflationsausgleichsprämie. Der Tarifvertrag läuft bis zum 30. September 2024.
          </p>
          <p>
            Eine Erhöhung, die im Jahr 2021 beschlossen wurde, wird mit diesem Tarifvertrag wieder aufgehoben. Nämlich die Erhöhung des Transformationsgeldes (T-Zug T).
            Ursprünglich sollte diese Zahlung im Jahr 2023 auf 27,6% steigen. Sie bleibt allerdings auf 18,4%.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
