import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ImpressumComponent } from './components/impressum/impressum.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookieConsentBannerComponent } from './components/cookie-consent-banner/cookie-consent-banner.component';
import { GoogleAdsenseComponent } from './components/google-adsense/google-adsense.component';

@NgModule({
  declarations: [
    ImpressumComponent,
    PrivacyPolicyComponent,
    CookieConsentBannerComponent,
    GoogleAdsenseComponent,
  ],
  imports: [CommonModule, MatButtonModule, RouterModule, MatSnackBarModule],
  exports: [GoogleAdsenseComponent],
})
export class SharedModule {}
