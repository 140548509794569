<div class="row">
  <div class="col-12">
    <h1 class="about">Tarifinformationen</h1>
    <p>
      Wenn es um Arbeitsbedingungen in der Metall- und Elektroindustrie geht,
      ist der IG Metall Tarifvertrag ein wichtiger Faktor für viele
      Arbeitnehmer. Dieser Tarifvertrag regelt nicht nur das Gehalt, sondern
      auch viele andere Aspekte des Arbeitslebens in der Branche.
    </p>

    <p>
      Der Metall- und Elektro-Tarifvertrag wird regelmäßig zwischen der IG
      Metall und den Arbeitgebern verhandelt. Ziel ist es, faire
      Arbeitsbedingungen für alle Arbeitnehmer zu schaffen. In diesem Vertrag
      sind alle Gehalts- und Arbeitsbedingungen für Arbeitnehmer in der Branche
      festgelegt.
    </p>

    <p>
      Eine der wichtigsten Fragen für viele Arbeitnehmer ist das Gehalt. Der IG
      Metall Tarifvertrag enthält klare Regelungen für die Gehaltsstruktur in
      der Branche. Die Höhe des Gehalts hängt dabei von verschiedenen Faktoren
      ab, wie beispielsweise der Position, der Qualifikation und der
      Berufserfahrung des Arbeitnehmers.
    </p>

    <p>
      Im Tarifvertrag sind auch Regelungen für Urlaubstage, Arbeitszeiten und
      Überstunden enthalten. Darüber hinaus wird auch die betriebliche
      Altersvorsorge geregelt, um den Arbeitnehmern eine sichere finanzielle
      Zukunft zu gewährleisten.
    </p>

    <p>
      Neben den direkten Arbeitsbedingungen enthält der IG Metall Tarifvertrag
      auch Regelungen für die Aus- und Weiterbildung der Arbeitnehmer.
      Unternehmen sind verpflichtet, ihre Mitarbeiter regelmäßig fortzubilden
      und weiterzubilden, um ihnen Karrieremöglichkeiten und persönliche
      Entwicklung zu bieten.
    </p>

    <p>
      Für Arbeitnehmer ist es wichtig, sich über den IG Metall Tarifvertrag und
      die darin enthaltenen Regelungen und Leistungen zu informieren. Die IG
      Metall setzt sich für faire Arbeitsbedingungen ein und setzt sich aktiv
      für die Rechte der Arbeitnehmer in der Metall- und Elektroindustrie ein.
    </p>

    <p>
      Zusammenfassend lässt sich sagen, dass der IG Metall Tarifvertrag eine
      wichtige Rolle bei der Festlegung fairer Arbeitsbedingungen und Gehälter
      in der Metall- und Elektroindustrie spielt. Arbeitnehmer sollten sich über
      den Tarifvertrag informieren, um von seinen Vorteilen und Regelungen
      profitieren zu können.
    </p>
  </div>
</div>
